<template>
  <div class="content">
    <a-row>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="守未服务" name="first"></el-tab-pane>
        <el-tab-pane label="检察服务" name="second"></el-tab-pane> </el-tabs
    ></a-row>
    <template v-if="empty">
      <a-row
        type="flex"
        justify="center"
        style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
      >
        <el-empty :description="description"></el-empty>
      </a-row>
    </template>
    <template v-else>
      <a-row
        :gutter="24"
        style="margin-top: 20px; min-height: 500px"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <a-col
          :span="24"
          :lg="12"
          v-for="item in list"
          :key="item.id"
          @click="onItemClick(item)"
        >
          <el-card class="box-card">
            <div class="row-title">
              <div class="row-title--type">
                {{ findServiceNameDes(item) }}
              </div>
              <div
                class="row-title--type--des"
                :style="{ color: findColorDes(item.status) }"
              >
                {{ findStatusDes(item.status) }}
              </div>
            </div>
            <div class="row-title">
              <div class="row-title--content">咨询部门：</div>
              <div class="row-title--content--des">
                {{ findDepartDes(item) }}
              </div>
            </div>
            <div class="row-title">
              <div class="row-title--content">申请时间：</div>
              <div class="row-title--content--des">
                {{ item.createTime ? item.createTime : "" }}
              </div>
            </div>
            <div class="row-title" v-if="item.status == 0">
              <div class="row-title--content">处理倒计时：</div>
              <div
                class="row-title--content--des"
                :class="
                  item.countDownDate.includes('超时') ? 'period-class' : 'inner-class'
                "
              >
                {{ item.countDownDate ? item.countDownDate : "" }}
              </div>
            </div>
          </el-card>
        </a-col>
      </a-row>
    </template>
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      @close="dialogVisible = false"
      :destroy-on-close="true"
      style="overflow: hidden"
    >
      <div style="overflow-y: scroll; height: 65vh">
        <template v-if="shortType == 0">
          <a-row :gutter="20">
            <a-col :span="24">
              <div class="details-item--title">申请信息</div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">咨询编号</span>{{ detailsData.number }}
              </div></a-col
            >
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">申请人</span>{{ detailsData.userName }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">身份证号：</span>{{ detailsData.idCard }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">联系电话</span>{{ detailsData.contactNumber }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">咨询部门</span
                >{{ detailsData.departmentName }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">服务类型</span>{{ detailsData.serviceName }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">状态</span>
                <el-tag type="danger" v-if="detailsData.status === 0">待处理</el-tag>
                <el-tag type="warning" v-if="detailsData.status === 1">处理中</el-tag>
                <el-tag type="success" v-if="detailsData.status === 2">已完成</el-tag>
                <el-tag type="info" v-if="detailsData.status === 3">已驳回</el-tag>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">公开状态</span>
                <el-tag type="success" v-if="detailsData.openStatus === 1">公开</el-tag>
                <el-tag type="warning" v-else>不公开</el-tag>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">所属地区</span>
                {{ detailsData.damageProvinces }}
                {{ detailsData.damageCity }}
                {{ detailsData.county }}
                {{ detailsData.villagesAndTowns }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <span class="details-title">需求说明</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                {{ detailsData.demand }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <span class="details-title">相关资料</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <div v-if="detailsData.imageList && detailsData.imageList.length > 0">
                  <span v-for="item of detailsData.imageList" :key="item">
                    <video
                      v-if="item.indexOf('mp4') > 0"
                      :src="item"
                      style="
                        height: 300px;
                        aspect-ratio: 16/9;
                        vertical-align: top;
                        margin: 0 10px 10px 0;
                        display: block;
                        border-radius: 10px;
                      "
                      controls
                    ></video>
                    <el-image
                      v-else
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 0 15px 15px 0;
                        vertical-align: top;
                        border-radius: 5px;
                      "
                      :src="item"
                      :preview-src-list="[item]"
                      fit="cover"
                    />
                  </span>
                </div>
                <div v-else>-</div>
              </div>
            </a-col>
            <template v-if="detailsData.agentsName && detailsData.agentsName.length != 0">
              <a-col :span="24">
                <div class="details-item--title">代理人信息</div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">代理人姓名</span
                  >{{ detailsData.agentsName }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">身份证号</span
                  >{{ detailsData.agentsIdCard }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">联系人电话</span
                  >{{ detailsData.agentsMobile }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">代理关系</span
                  >{{ detailsData.agentsContact }}
                </div>
              </a-col>
            </template>
            <template v-if="detailsData.explain">
              <a-col :span="24">
                <div class="details-item--title">回复内容</div>
              </a-col>
              <a-col :span="24">
                <div class="details-item" style="width: 100%">
                  {{ detailsData.explain }}
                </div>
              </a-col>
              <a-col :span="24">
                <div class="details-item" style="width: 100%">
                  <div
                    v-if="
                      detailsData.serviceAnnexList &&
                      detailsData.serviceAnnexList.length > 0
                    "
                  >
                    <span v-for="item of detailsData.serviceAnnexList" :key="item">
                      <video
                        v-if="item.indexOf('mp4') > 0"
                        :src="item"
                        style="
                          height: 300px;
                          aspect-ratio: 16/9;
                          vertical-align: top;
                          margin: 0 10px 10px 0;
                          display: block;
                          border-radius: 10px;
                        "
                        controls
                      ></video>
                      <el-image
                        v-else
                        style="
                          width: 100px;
                          height: 100px;
                          margin: 0 15px 15px 0;
                          vertical-align: top;
                          border-radius: 5px;
                        "
                        :src="item"
                        :preview-src-list="[item]"
                        fit="cover"
                      />
                    </span>
                  </div>
                  <div v-else>-</div>
                </div>
              </a-col>
            </template>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="20">
            <a-col :span="24">
              <div class="details-item--title">未成年信息</div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">姓名</span>{{ detailsData.nonageName }}
              </div></a-col
            >
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">性别</span
                >{{ detailsData.nonageSex ? detailsData.nonageSex : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">未成年身份</span
                >{{ detailsData.nonageIdentity ? detailsData.nonageIdentity : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">在原案中的诉讼地位</span
                >{{ detailsData.lawsuitStatus ? detailsData.lawsuitStatus : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">曾用名</span
                >{{ detailsData.formerName ? detailsData.formerName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">绰号</span
                >{{ detailsData.nickname ? detailsData.nickname : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">证件类型</span
                >{{
                  detailsData.nonageDocumentType ? detailsData.nonageDocumentType : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">证件号码</span
                >{{
                  detailsData.nonageDocumentNumber
                    ? detailsData.nonageDocumentNumber
                    : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">出生日期</span
                >{{ detailsData.dateOfBirth ? detailsData.dateOfBirth : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">民族</span
                >{{ detailsData.nonageNation ? detailsData.nonageNation : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">国籍</span
                >{{
                  detailsData.nonageNationality ? detailsData.nonageNationality : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">案发时年龄</span
                >{{
                  detailsData.ageWhenCrimeHappened
                    ? detailsData.ageWhenCrimeHappened
                    : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">户籍所在地</span
                >{{
                  detailsData.censusRegisterSeat ? detailsData.censusRegisterSeat : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">住所地</span
                >{{ detailsData.local ? detailsData.local : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">住所地详细地址</span
                >{{
                  detailsData.detailedAddressOf ? detailsData.detailedAddressOf : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">工作单位/学校</span
                >{{ detailsData.schoolName ? detailsData.schoolName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">工作单位/学校所在地</span
                >{{ detailsData.schoolAddress ? detailsData.schoolAddress : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">法定代理人</span
                >{{
                  detailsData.legalRepresentative ? detailsData.legalRepresentative : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">监护情况</span
                >{{ detailsData.guardianshipIs ? detailsData.guardianshipIs : "无" }}
              </div>
            </a-col>

            <a-col :span="24">
              <div class="details-item--title">控告人/申请人</div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">姓名或单位名称</span
                >{{ detailsData.applyName ? detailsData.applyName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">与未成年人的关系</span
                >{{ detailsData.applyRelation ? detailsData.applyRelation : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">性别</span
                >{{ detailsData.applySex ? detailsData.applySex : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">证件类型</span
                >{{
                  detailsData.applyDocumentType ? detailsData.applyDocumentType : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">证件号码</span
                >{{ detailsData.dateOfBirth ? detailsData.dateOfBirth : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">证件号码</span
                >{{
                  detailsData.applyDocumentNumber ? detailsData.applyDocumentNumber : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">民族</span
                >{{ detailsData.applyNation ? detailsData.applyNation : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">国籍</span
                >{{ detailsData.applyNationality ? detailsData.applyNationality : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">所在地区</span
                >{{ detailsData.applyInArea ? detailsData.applyInArea : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">单位/住址</span
                >{{ detailsData.applyAddress ? detailsData.applyAddress : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">身份</span
                >{{ detailsData.applyIdentity ? detailsData.applyIdentity : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">职务</span
                >{{ detailsData.applyPosition ? detailsData.applyPosition : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">联系电话</span
                >{{ detailsData.applyMobile ? detailsData.applyMobile : "无" }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item--title">被控告人/被申诉人</div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">姓名</span
                >{{ detailsData.personName ? detailsData.personName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">性别</span
                >{{ detailsData.personSex ? detailsData.personSex : "无" }}
              </div> </a-col
            ><a-col :span="12">
              <div class="details-item">
                <span class="details-title">身份</span
                >{{ detailsData.personIdentity ? detailsData.personIdentity : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">职务</span
                >{{ detailsData.personPosition ? detailsData.personPosition : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">其他关注身份</span
                >{{
                  detailsData.personOtherIdentity ? detailsData.personOtherIdentity : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">户籍/单位所在地</span
                >{{ detailsData.personLocal ? detailsData.personLocal : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">住址</span
                >{{ detailsData.personAddress ? detailsData.personAddress : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">工作单位</span
                >{{ detailsData.personUnits ? detailsData.personUnits : "无" }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item--title">事项/内容</div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">接收地区</span
                >{{ detailsData.contentAddress ? detailsData.contentAddress : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">控告事项发生时间</span
                >{{ detailsData.contentTime ? detailsData.contentTime : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">申诉所涉机关类型</span
                >{{ detailsData.authorityType ? detailsData.authorityType : "无" }}
              </div> </a-col
            ><a-col :span="12">
              <div class="details-item">
                <span class="details-title">申诉所涉机关名称</span
                >{{ detailsData.authorityName ? detailsData.authorityName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">申诉类别</span
                >{{
                  detailsData.complaintCategory ? detailsData.complaintCategory : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">原案发生时间</span
                >{{ detailsData.originalTime ? detailsData.originalTime : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">被救助人类别</span
                >{{
                  detailsData.rescuedOneCategory ? detailsData.rescuedOneCategory : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">原案案由</span
                >{{
                  detailsData.originalCauseOfAction
                    ? detailsData.originalCauseOfAction
                    : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">原办案检察机关名称</span
                >{{ detailsData.originalCaseName ? detailsData.originalCaseName : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">申请救助类别</span
                >{{
                  detailsData.applyForReliefCategory
                    ? detailsData.applyForReliefCategory
                    : "无"
                }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">申请理由</span
                >{{ detailsData.applyForReason ? detailsData.applyForReason : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">控告刑事案由/原案案由</span
                >{{ detailsData.criminalCasesBy ? detailsData.criminalCasesBy : "无" }}
              </div>
            </a-col>
            <a-col :span="12">
              <div class="details-item">
                <span class="details-title">控告其他刑事案由</span
                >{{
                  detailsData.otherCriminalCasesBy
                    ? detailsData.otherCriminalCasesBy
                    : "无"
                }}
              </div>
            </a-col>

            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">说明内容/申诉内容/申请救助内容</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item">
                {{ detailsData.contentDesc ? detailsData.contentDesc : "无" }}
              </div>
            </a-col>

            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">原案情摘要</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item">
                {{
                  detailsData.originalCaseAbstracts
                    ? detailsData.originalCaseAbstracts
                    : "无"
                }}
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">控告材料/申诉状/相关材料</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <div
                  v-if="
                    detailsData.chargedMaterial && detailsData.chargedMaterial.length > 0
                  "
                >
                  <span v-for="item of detailsData.chargedMaterial" :key="item">
                    <video
                      v-if="item.indexOf('mp4') > 0"
                      :src="item"
                      style="
                        height: 300px;
                        aspect-ratio: 16/9;
                        vertical-align: top;
                        margin: 0 10px 10px 0;
                        display: block;
                        border-radius: 10px;
                      "
                      controls
                    ></video>
                    <el-image
                      v-else
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 0 15px 15px 0;
                        vertical-align: top;
                        border-radius: 5px;
                      "
                      :src="item"
                      :preview-src-list="[item]"
                      fit="cover"
                    />
                  </span>
                </div>
                <div v-else>-</div>
              </div>
            </a-col>

            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">其他材料/复查文书</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <div
                  v-if="
                    detailsData.otherMaterials && detailsData.otherMaterials.length > 0
                  "
                >
                  <span v-for="item of detailsData.otherMaterials" :key="item">
                    <video
                      v-if="item.indexOf('mp4') > 0"
                      :src="item"
                      style="
                        height: 300px;
                        aspect-ratio: 16/9;
                        vertical-align: top;
                        margin: 0 10px 10px 0;
                        display: block;
                        border-radius: 10px;
                      "
                      controls
                    ></video>
                    <el-image
                      v-else
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 0 15px 15px 0;
                        vertical-align: top;
                        border-radius: 5px;
                      "
                      :src="item"
                      :preview-src-list="[item]"
                      fit="cover"
                    />
                  </span>
                </div>
                <div v-else>-</div>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item">
                <span class="details-title">申诉人身份证扫描件</span>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="details-item" style="width: 100%">
                <div
                  v-if="
                    detailsData.idCardScanning && detailsData.idCardScanning.length > 0
                  "
                >
                  <span v-for="item of detailsData.idCardScanning" :key="item">
                    <video
                      v-if="item.indexOf('mp4') > 0"
                      :src="item"
                      style="
                        height: 300px;
                        aspect-ratio: 16/9;
                        vertical-align: top;
                        margin: 0 10px 10px 0;
                        display: block;
                        border-radius: 10px;
                      "
                      controls
                    ></video>
                    <el-image
                      v-else
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 0 15px 15px 0;
                        vertical-align: top;
                        border-radius: 5px;
                      "
                      :src="item"
                      :preview-src-list="detailsData.idCardScanning"
                      fit="cover"
                    />
                  </span>
                </div>
                <div v-else>-</div>
              </div>
            </a-col>

            <template v-if="detailsData.status === 2">
              <a-col :span="24">
                <div class="details-item--title">处理结果</div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">处理单位</span
                  >{{
                    detailsData.replyCompanyName ? detailsData.replyCompanyName : "无"
                  }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">处理人</span
                  >{{ detailsData.replyUserName ? detailsData.replyUserName : "无" }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">处理电话</span
                  >{{
                    detailsData.replyPhoneNumber ? detailsData.replyPhoneNumber : "无"
                  }}
                </div>
              </a-col>
              <a-col :span="12">
                <div class="details-item">
                  <span class="details-title">处理时间</span
                  >{{ detailsData.replyTime ? detailsData.replyTime : "无" }}
                </div>
              </a-col>
              <a-col :span="24">
                <div class="details-item--title">处理内容</div>
              </a-col>
              <a-col :span="24">
                <div class="details-item">
                  {{ detailsData.dealContent ? detailsData.dealContent : "无" }}
                </div>
              </a-col>
              <a-col :span="24">
                <div class="details-item" style="width: 100%">
                  <div
                    v-if="
                      detailsData.replyAnnexList && detailsData.replyAnnexList.length > 0
                    "
                  >
                    <span v-for="item of detailsData.replyAnnexList" :key="item">
                      <video
                        v-if="item.indexOf('mp4') > 0"
                        :src="item"
                        style="
                          height: 300px;
                          aspect-ratio: 16/9;
                          vertical-align: top;
                          margin: 0 10px 10px 0;
                          display: block;
                          border-radius: 10px;
                        "
                        controls
                      ></video>
                      <el-image
                        v-else
                        style="
                          width: 100px;
                          height: 100px;
                          margin: 0 15px 15px 0;
                          vertical-align: top;
                          border-radius: 5px;
                        "
                        :src="item"
                        :preview-src-list="[item]"
                        fit="cover"
                      />
                    </span>
                  </div>
                </div>
              </a-col>
            </template>
          </a-row>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { log } from "console";
import { countDownAction } from "../../utils/index";
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      empty: false,
      description: "暂无数据",
      shortType: 0,
      list: [],
      timer: null,
      detailsData: {},
      orderStatus: [
        {
          id: 0,
          name: "已提交申请",
          color: "#0090D7",
        },
        {
          id: 1,
          name: "已服务",
          color: "#51D2BE",
        },
        {
          id: 2,
          name: "已完成",
          color: "#939999",
        },
        {
          id: 3,
          name: "已驳回",
          color: "#FD6161",
        },
      ],
    };
  },
  created() {
    this.getListData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  },
  methods: {
    onItemClick(item) {
      let params = {
        consultId: item.id,
        id: item.id,
      };
      this.$HTTP
        .httpToken({
          url:
            this.shortType == 0
              ? "/shouwei/service/consult/detail"
              : "/shouwei/service/v2/details",
          method: "post",
          data: params,
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            let item = this.initChargedMaterial(res.data);
            item.countDownDate = "计算中";
            this.detailsData = item;
            this.dialogVisible = true;
          } else {
            this.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
              duration: 3000,
            });
          }
        })
        .catch((e) => {});
    },
    initChargedMaterial(item) {
      if (item.chargedMaterial) {
        if (item.chargedMaterial.indexOf(",") > -1) {
          item.chargedMaterial = item.chargedMaterial
            ? item.chargedMaterial.split(",")
            : [];
        } else {
          let temp = item.chargedMaterial;
          item.chargedMaterial = [];
          item.chargedMaterial.push(temp);
        }
      } else {
        item.chargedMaterial = [];
      }
      if (item.otherMaterials) {
        if (item.otherMaterials.indexOf(",") > -1) {
          item.otherMaterials = item.otherMaterials ? item.otherMaterials.split(",") : [];
        } else {
          let temp = item.otherMaterials;
          item.otherMaterials = [];
          item.otherMaterials.push(temp);
        }
      } else {
        item.otherMaterials = [];
      }
      if (item.idCardScanning) {
        if (item.idCardScanning.indexOf(",") > -1) {
          item.idCardScanning = item.idCardScanning ? item.idCardScanning.split(",") : [];
        } else {
          let temp = item.idCardScanning;
          item.idCardScanning = [];
          item.idCardScanning.push(temp);
        }
      } else {
        item.idCardScanning = [];
      }
      return item;
    },
    findDepartDes(item) {
      if (this.shortType == 0) {
        return item.deptName ? item.deptName : "";
      } else {
        return (
          (item.contentDealCity ? item.contentDealCity : "") +
          (item.contentDealCounty ? item.contentDealCounty : "") +
          (item.department ? item.department : "")
        );
      }
    },
    findColorDes(status) {
      let items = this.orderStatus.filter((item) => {
        return item.id == status;
      });
      return items[0] ? items[0].color : "#000000";
    },
    findStatusDes(status) {
      let items = this.orderStatus.filter((item) => {
        return item.id == status;
      });
      console.log(items);
      return items[0] ? items[0].name : "1231";
    },
    findServiceNameDes(item) {
      if (this.shortType == 0) {
        return item.serviceName ? item.serviceName : "";
      } else {
        return item.serviceType ? item.serviceType : "";
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.shortType = Number.parseInt(tab.index);
      this.getListData();
    },
    countDown(list) {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = undefined;
      }
      let that = this;
      this.timer = setInterval(() => {
        list.forEach((item) => {
          // console.log("处理倒计时", item.createTime, item.countDownDate);
          countDownAction(item, item.createTime, 30);
        });
      }, 1000);
    },
    getListData() {
      let that = this;
      this.loading = true;
      let params = {
        lastId: "",
      };
      this.$HTTP
        .httpToken({
          url:
            this.shortType == 0
              ? "/shouwei/service/consult/list"
              : "/shouwei/service/v2/my",
          method: "post",
          data: params,
        })
        .then((res) => {
          console.log("课程", res);
          that.loading = false;
          that.list = [];
          if (res.code == 0 && res.data && res.data.length > 0) {
            that.empty = false;
            res.data.forEach((item) => {
              item.countDownDate = "";
            });
            that.countDown(res.data);
            that.list.push(...res.data);
          } else {
            that.empty = true;
            that.description = "暂无数据";
          }
        })
        .catch((e) => {
          that.loading = false;
          that.empty = true;
          that.description = e;
        });
    },
  },
};
</script>
<style lang="scss">
.details-info {
  margin: 15px 0;
}

.details-container {
  line-height: 2;
  font-size: 14px;
}

.details-item {
  display: inline-block;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
  &--title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.details-title {
  display: inline-block;
  margin-right: 15px;
  font-weight: bold;
}
.box-card {
  margin-bottom: 12px;
}
.period-class {
  color: orangered;
}

.inner-class {
  color: #0090d7;
}
.row-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &--type {
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    flex: 1;
    text-align: left;
    &--des {
      color: #0090d7;
      font-size: 16px;
    }
  }
  &--content {
    font-size: 16px;
    &--des {
      font-size: 17px;
      font-weight: 500;
    }
  }
  :first-child {
    margin-bottom: 10px;
  }
}
</style>
